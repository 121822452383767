import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const ThankYou = () => (
  <Layout>
    <SEO title="Fitness and Spirit - Contact" />
    <div className="container-main">
      <div className="container-main_div">
        <div className="text-center h-40">
          <h2>Thank you for your submission!</h2>
        </div>
      </div>
    </div>
  </Layout>
)

export default ThankYou
